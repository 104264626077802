body {
  margin: 0;
  font-family: "Museo Sans", sans-serif;
}
ul {
  padding-left: 1em;
  margin-bottom: 10px;
  li {
    list-style: disc;
    ul {
      margin-bottom: 0px;
    }
  }
}

p {
  margin-bottom: 1em;
}

.app-container {
  @media (max-aspect-ratio: 4/5) {
    margin-top: 0;
  }
}

#v0 {
    position: fixed;
    top: 0;
    left: 0;
    aspect-ratio: 16 / 9;
    max-height: 1080px;
    width: 100%;
    max-width: 1920px;
    object-fit: cover;
    z-index: 0;
    background: url("./assets/blurredbg.jpg");
    background-size: cover;
    box-shadow: 0px 0px 20px #222222 ;
    @media (max-aspect-ratio: 3/2) {
      object-fit: contain;
    }
    @media (max-aspect-ratio: 4/5) {
      height: auto;
      z-index: 2;
    }
}
#scrollcontainer {
  position: relative;
}

.closemodal {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
}

.slide {
    z-index: 1;
    width: 100%;
    position: relative;
    &.first {
      z-index: 4;
    }
    @media (max-aspect-ratio: 4/5) {
      z-index: 0;
    }
}

.content {
    position: sticky;
    position: -webkit-sticky;
    width: 100%;
    &.lightmargin {
      top: 10vh;
    }
}

.wp-block-image {
  text-align: center;
  margin-bottom: 2em;
}

// section:nth-of-type(1) {
//     height: 200vh;
// }
// section:nth-of-type(2) {
//     height: 200vh;
// }
// section:nth-of-type(3) {
//     height: 110vh;
// }
// section:nth-of-type(4) {
//     height: 90vh;
// }
// section:nth-of-type(5) {
//     height: 100vh;
// }
// section:nth-of-type(6) {
//   height: 100vh;
// }
// section:nth-of-type(7) {
//   height: 140vh;
// }
// section:nth-of-type(8) {
//   height: 200vh;
// }

.shadow {
  filter: drop-shadow(0px 2px 2px rgb(0 0 0 / 40%));
  &-inset {
    box-shadow: inset 0px 2px 3px rgb(0 0 0 / 40%);
  }
}

.tool-cat {
  transition: all ease-in-out .25s;
}

.entered {
	-webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.toobox-nav {
  font-size: 4em;
  &.active {
    margin-right: -1em;
    &::after {
      content: '';
      height: 100%;
      width: 1em;
      background-color: inherit;
      position: relative;
      right: -1em;
      top:0;
    }
  }
}

.mobile-header {
  position: sticky;
  top:0;
}

.toolbox-container {
  z-index: 2;
}

/* ----------------------------------------------
 * Generated by Animista on 2018-7-4 16:24:12
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
 @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  .bounce-bottom {
    animation: bounce-bottom 6s both infinite;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-7-11 16:15:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-bottom
 * ----------------------------------------
 */
@keyframes bounce-bottom {
  1% {
    animation-timing-function: ease-out;
  }
  24% {
    transform: translateY(6px);
    animation-timing-function: ease-in;
    opacity: 1;
  }
}
